import React, { PropsWithChildren } from 'react'
import cx from 'classnames'

import { InputProps } from '../model'

import styles from './style.module.scss'

export const View = ({
	name,
	label,
	helperText,
	value,
	error,
	inputRef,
	dropdownPlacement,
	simpleInputs = false,
	placeholder = '',
	endAdornment: EndAdornment,
	...rest
}: PropsWithChildren<Omit<InputProps, 'transformValue'>>) => {
	return (
		<>
			<div
				className={cx({
					[styles.inputWrap]: !simpleInputs,
					[styles.simpleInputWrap]: simpleInputs,
				})}
			>
				{simpleInputs && (
					<label htmlFor={rest.id} className={styles.simpleLabel}>
						{label}
					</label>
				)}
				<input
					{...rest}
					{...{ 'data-dropdown-placement': dropdownPlacement }}
					ref={inputRef}
					name={name}
					className={cx(styles.input, rest.className, {
						[styles.hasValue]: !!value,
						[styles.inputError]: !!error,
						[styles.simpleInput]: simpleInputs,
					})}
					type='text'
					value={value}
					placeholder={simpleInputs ? placeholder : ''}
				/>
				{!simpleInputs && (
					<label htmlFor={rest.id} className={styles.label}>
						{label}
					</label>
				)}
				{error && !rest.disabled ? (
					<p className={styles.error}>{error}</p>
				) : (
					helperText && <p className={styles.helpText}>{helperText}</p>
				)}
				{EndAdornment && (
					<div>{typeof EndAdornment === 'function' ? <EndAdornment /> : EndAdornment}</div>
				)}
			</div>
		</>
	)
}
